/*********** TABLE OF CONTENTS **************
1. Fonts
2. Reset
3. Global
4. Main Header / Two
5. Sticky Header
6. Mobile Menu
7. Page Banner
8. Section Title
9. Banner Section / Two
10. Welcome Section
11. Gallery Section
12. Matches Section
13. Gallery Section Two
14. Questions Section
15. Facts Section
16. News Section
17. Sponsors Section
18. Main Footer
19. Games Section
20. Welcome Section Two
21. Players Section
22. Call to Action
23. Contact Form Section
24. About Section
25. Player Info Section
26. Awards Section
27. FAQ Section
28. Gallery Section Three
29. Match Details
30. Sidebar
31. Comments Area
32. Blog Post Details

**********************************************/

/*** 

====================================================================
			Fonts
====================================================================

 ***/

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Special+Elite&display=swap');

/*
font-family: 'Roboto Condensed', sans-serif;

*/

@import url('../css/fontawesome-all.css');
@import url('../css/animate.css');
@import url('../css/flaticon.css');
@import url('../css/owl.css');
@import url('../css/jquery-ui.css');
@import url('../css/jquery.fancybox.min.css');
@import url('../css/scrollbar.css');
@import url('../css/hover.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

/*** 

====================================================================
	Reset
====================================================================

 ***/

*{
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
	font-size: 100%;
	line-height: inherit;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	
	font-size:14px;
	color:#787878;
	line-height:1.6em;
	font-weight:400;
	background:#0c0a0a;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-family: 'Roboto Condensed', sans-serif;
	/* user-select: none; */
}

a{
	text-decoration:none;
	cursor:pointer;
	color:#ff7d00;
}

a:hover,
a:focus,
a:visited{
	text-decoration:none;
	outline:none;
	color:#ff7d00;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.2em;
}

textarea{
	overflow:hidden;	
}

button{
	outline: none !important;
	cursor: pointer;
}

p,.text{
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
	color: #696478;
	margin: 0;
}

::-webkit-input-placeholder{color: #848484;}
::-moz-input-placeholder{color: #848484;}
::-ms-input-placeholder{color: #848484;}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	min-width:300px;
	z-index: 9;
	overflow: hidden;
}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

figure{
	margin-bottom: 0;
}

.theme-btn{
	display:inline-block;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.theme-btn i{
	position: relative;
	display: inline-block;
	font-size: 14px;
	margin-left: 3px;
}

.centered{
	text-align:center !important;
}

/*Btn Style One*/

.btn-style-one{
	position: relative;
	display: inline-block;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 700;
	letter-spacing:1px;
	text-transform:uppercase;
}

.btn-style-one:hover{
	color: #ff7d00;
}

.btn-style-one .btn-title{
	position: relative;
	display:block;
	z-index:1;
	padding: 18px 56px;
	background-color: #ff7d00;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.btn-style-one:hover .btn-title{
	color:#ff7d00;
	-webkit-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-moz-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-ms-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-o-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	box-shadow: 0 10px 30px rgba(0,0,0,0.10);
}

.btn-style-one .btn-title:before{
	position: absolute;
	left: 0;
	top: 0;
	z-index:-1;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.btn-style-one:hover  .btn-title:before{
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/* Btn Style Two */

.btn-style-two{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	color: #ff7d00;
	font-weight: 400;
	letter-spacing:1px;
	text-transform:uppercase;
}

.btn-style-two:hover{
	color: #ff7d00;
}

.btn-style-two .btn-title{
	position: relative;
	display:block;
	z-index:1;
	padding: 8px 28px;
	background-color: #ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.btn-style-two:hover .btn-title{
	color:#ffffff;
	-webkit-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-moz-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-ms-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-o-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	box-shadow: 0 10px 30px rgba(0,0,0,0.10);
}

.btn-style-two .btn-title:before{
	position: absolute;
	left: 0;
	top: 0;
	z-index:-1;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ff7d00;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.btn-style-two:hover  .btn-title:before{
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/*Btn Style Three*/

.btn-style-three{
	position:relative;
	font-size:16px;
	line-height:30px;
	color:#f1ecff;
	font-weight:500;
	padding: 10px 32px;
	background-color: #1a2940;
	overflow: hidden;
	border-radius: 5px;
	transition: all 300ms linear;
}

.btn-style-three .btn-title{
	position: relative;
}

.btn-style-three:hover{
	color:#1a2940;
	-webkit-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-moz-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-ms-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	-o-box-shadow: 0 10px 30px rgba(0,0,0,0.10);
	box-shadow: 0 10px 30px rgba(0,0,0,0.10);
}

.btn-style-three:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background-color: #ffffff;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
}

.btn-style-three:hover:before{
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	transform-origin: bottom left;
}

/*Btn Style Four */

.btn-style-four{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 28px;
	color: #ffffff;
	padding: 10px 30px;
	font-weight: 500;
	overflow: hidden;
	border: 1px solid #333333;
	background-color: #333333;
}

.btn-style-four:hover{
	color: #333333;
	border: 1px solid #ffffff;
	-webkit-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
	-moz-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
	-ms-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
	-o-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
	box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
}

/*=== Social Icon One ===*/

.social-icon-one{
	position: relative;
}

.social-icon-one li{
	position: relative;
	display: inline-block;
	margin-left: 40px;
}

.social-icon-one li a{
	position: relative;
	display: block;
	font-size: 19px;
	line-height: 30px;
	color: #341c77;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon-one li a:hover{
	color: #ff7d00;
}

.social-icon-one li:first-child{
	margin-left: 0;
}

/*=== Social Icon Two ===*/

.social-icon-two{
	position: relative;
}

.social-icon-two li{
	position: relative;
	display: inline-block;
	margin-left: 30px;
}

.social-icon-two li a{
	position: relative;
	display: block;
	font-size: 19px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon-two li a:hover{
	color: #ff7d00;
}

.social-icon-two li:first-child{
	margin-left: 0;
}

/*=== List Style One ===*/

.list-style-one{
	position:relative;
}

.list-style-one li{
	position:relative;
	font-size:18px;
	line-height:26px;
	color: #222222;
	font-weight:500;
	padding-left:25px;
	margin-bottom: 13px;
}

.list-style-one li:before{
	content: "\f00c";
	position:absolute;
	left: 0;
	top: 0px;
	display:block;
	font-size:14px;
	line-height: 26px;
	padding: 0px;
	color:#ff7d00;
	font-weight:900;
	font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover{
	color: #44bce2;
}

.theme_color{
	color:#ff7d00;
}

.pull-right{
	float: right;
}

.pull-left{
	float: left;
}

.preloader{ 
	position:fixed; 
	left:0px; 
	top:0px; 
	width:100%; 
	height:100%; 
	z-index:999999; 
	background-color:rgba(0,0,0,0); 
} 

.preloader .icon{ 
	position:fixed; 
	left:0px; 
	top:0px; 
	width:100%; 
	height:100%; 
	z-index:1; 
	background-color:rgba(0,0,0,0); 
	background-position:center center; 
	background-repeat:no-repeat; 
	background-image:url(../images/icons/preloader.svg);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
} 

.page-loaded .preloader .icon{
	opacity:0; 
}

.preloader:before{
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#ffffff;
	z-index:0; 
	-webkit-transform:translateX(0%);
	-ms-transform:translateX(0%);
	transform:translateX(0%);
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}

.page-loaded .preloader:before{
	-webkit-transform:translateX(-101%);
	-ms-transform:translateX(-101%);
	transform:translateX(-101%);
}

.preloader:after{
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#ff7d00;
	z-index:2; 
	-webkit-transform:translateX(101%);
	-ms-transform:translateX(101%);
	transform:translateX(101%);
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}

.page-loaded .preloader:after{
	-webkit-transform:translateX(-101%);
	-ms-transform:translateX(-101%);
	transform:translateX(-101%);
}

img{
	display:inline-block;
	max-width:100%;
	height:auto;	
}

.dropdown-toggle::after{
	display: none;
}

.fa{
	line-height: inherit;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position: relative;
	display: block;
	width: 100%;
	z-index: 99999;
	background-color:#0c0a0a;
}

.main-header .header-top{
	position: relative;
}

/* Top Left*/

.main-header .header-top .top-left{
	position: relative;
	float: left;
}

.main-header .header-top .info-list{
	position: relative;
	float: right;
}

.main-header .header-top .info-list li{
	position: relative;
	float: left;
	font-size: 15px;
	line-height: 30px;
	color: #838383;
	font-weight: 400;
	padding: 20px 0px;
}

.main-header .header-top .info-list li a{
	display: inline-block;
	color: #c8bee3;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .header-top .info-list li a:hover{
	color: #ff7d00;
}

.main-header .header-top .top-right{
	position:relative;
	float:right;
}

.main-header .header-top .top-right .social-icons{
	position:relative;
	padding:22px 0px;
}

.main-header .header-top .top-right .social-icons li{
	position:relative;
	margin-left:25px;
	display:inline-block;
}

.main-header .header-top .top-right .social-icons li a{
	position:relative;
	color:#ffffff;
	font-size:16px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .header-top .top-right .social-icons li a:hover{
	color:#ff7d00;
}

/*=== Header Upper ===*/

.main-header .header-upper{
	position: relative;
	background-color:#000000;
}

.main-header .header-upper .logo-outer{
	position: absolute;
	left: 50%;
	top: 0px;
	z-index:2;
	margin-top:-50px;
	transform: translateX(-50%);
}

/* .main-header .header-upper .logo-outer:before{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 1000%;
	background-color: #f7f2ea;
	content: "";
} */

.main-header .header-upper .logo{
	position: relative;
}

/* .main-header .header-upper .logo:before{
    position: absolute;
    right: -30px;
    top: -35px;
    height: 182px;
    width: 182px;
    background-color: #f7f2ea;
    content: "";
    border-radius: 50%;
} */

.main-header .header-upper .logo img{
	position: relative;
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.main-header .header-upper .social-links{
	position: relative;
	float: left;
	padding: 39px 0;
	margin-left: 75px;
}

.main-header .nav-outer{
	position: relative;
	float: right;
	width:100%;
	z-index:1;
}

.main-header .header-upper .nav-outer:before{
	position:absolute;
	content:'';
	left: 50%;
    top: 0px;
    z-index: 2;
	width:542px;
	height:158px;
    margin-top: -1px;
    transform: translateX(-50%);
	/* background:url(../images/icons/header-lower-bg.png) no-repeat; */
	background-position:center center;
}

/*===  Header Lower ===*/

.main-header .header-lower{
	position: relative;
	width: 100%;
	border-top:1px solid #232323;
	border-bottom:1px solid #232323;
}

.main-menu{
	position:relative;
	float:left;
	width:100%;
}

.main-menu .navbar-collapse{
	padding:0px;
	display:block !important;
}

.main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-menu .navigation > li{
	position:relative;
	float:left;
	z-index:2;
	padding:25px 0px;
	margin-right: 65px;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-menu .navigation > li:last-child{
	margin-right:0px;
}

.main-menu .navigation > li > a{
	position:relative;
	display:block;
	text-align:center;
	font-size:16px;
	line-height:30px;
	font-weight:700;
	opacity:1;
	color:#848484;
	padding: 0px;
	z-index:1;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
	text-transform:uppercase;
}

.main-menu .navigation > li > a:before{
	position:absolute;
	content:'';
	left:0px;
	top:50%;
	height:3px;
	width:0px;
	z-index:-1;
	margin-top:-2px;
	/* background-color:#ff7d00;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease; */
}

.sticky-header .main-menu .navigation > li > a:before{
	display:none;
}

.main-menu .navigation > li:hover > a:before,
.main-menu .navigation > li.current > a:before{
	width:100%;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a{
	color: #ffffff;
}

.main-menu .navigation > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:230px;
	z-index:100;
	display:none;
	opacity: 0;
    visibility: hidden;
	padding: 0px 0px;
	background-color: #ffffff;
	border-top: 2px solid #ff7d00;
	-moz-transform: translateY(30px);
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
    transform: translateY(30px);
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(0,0,0,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:0%;
	height:100%;
	display:block;
	background:#ff7d00;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover:before{
	width:100%;	
}

.main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px;
	line-height:24px;
	font-weight:500;
	font-size:15px;
	text-transform:capitalize;
	color:#252525;
	text-align: left;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sticky-header .main-menu .navigation > li > a{
	padding:19px 0px;
}

.sticky-header .main-menu .navigation > li > a:before{
	display: none;
}

.main-menu .navigation > li > ul > li:hover > a{
	color:#ffffff;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'Font Awesome 5 Free';
	content: "\f105";
	position:absolute;
	right:20px;
	top:12px;
	display:block;
	line-height:24px;
	font-size:16px;
	font-weight:800;
	text-align:center;
	z-index:5;	
}

.main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:-2px;
	width:230px;
	z-index:100;
	display:none;
    padding: 0px 0px;
	background-color: #ffffff;
	border-top: 2px solid #ff7d00;
	-moz-transform: translateY(30px);
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
    transform: translateY(30px);
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(0,0,0,0.10);
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li > ul > li:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:0%;
	height:100%;
	display:block;
	background:#ff7d00;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:hover:before{
	width:100%;	
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px;
	line-height:24px;
	font-weight:500;
	font-size:15px;
	text-transform:capitalize;
	color:#252525;
	text-align: left;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:#ffffff;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after{
	font-family: 'Font Awesome 5 Free';
	content: "\f105";
	position:absolute;
	right:20px;
	top:12px;
	display:block;
	line-height:24px;
	font-size:16px;
	font-weight:900;
	z-index:5;	
}

.main-menu .navigation > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
    transform: translateY(0);
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;	
}

.main-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:8px;
	width:34px;
	height:30px;
	border:1px solid #ffffff;
	text-align:center;
	font-size:16px;
	line-height:26px;
	color:#ffffff;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-header .outer-box{
	position: relative;
	float: right;
	padding: 30px 0;
}

.main-header .outer-box .btn-box{
	position: relative;
	float: right;
}

.main-header .outer-box .btn-box .theme-btn{
	text-transform: uppercase;
	display: block;
}

/*search box btn*/
.main-header .search-box-outer{
	position:relative;
	float: left;
	padding: 10px 30px;
	margin-left: 30px;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .search-box-outer:before{
	position: absolute;
	left: 0;
	top: 13px;
	height: 24px;
	width: 1px;
	background-color: #e8e8e8;
	content: "";
}

.main-header .search-box-btn{
	position: relative;
	float: left;
    font-size: 16px;
	line-height: 30px;
    background: none;
    color: #222222;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-header .search-box-btn:hover{
	color: #343434;
}

.dropdown-toggle::after{
	display: none;
}

.main-header .search-box-outer .dropdown-menu{
	top:70px !important;
	left: auto !important;
	right: 0;
	padding:0px;
	width:280px;	
	border-radius:0px;
	-webkit-transform: none !important;
	-ms-transform: none !important;
	transform: none !important;
	border-top:3px solid #ff7d00;
}

.main-header .search-panel .form-container{
	padding:25px 20px;	
}

.main-header .search-panel .form-group{
	position:relative;
	margin:0px;	
}

.main-header .search-panel input[type="text"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="password"],
.main-header .search-panel select{
	display:block;
	width:100%;
	line-height:24px;
	padding:7px 40px 7px 15px;
	height:40px;
	font-size:14px;
	border:1px solid #e0e0e0;
	background:#ffffff;	
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus{
	border-color:#25262c;	
}

.main-header .search-panel .search-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:40px;
	height:40px;
	text-align:center;
	color:#555555;
	font-size:12px;
	background:none;
	cursor:pointer;
}

.main-header .outer-box .social-links{
	position: relative;
	float: left;
	margin-left: 20px;
	padding: 20px 0;
	padding-left: 20px;
}

.main-header .outer-box .social-links:before{
	position: absolute;
	left: -3px;
	top: 15px;
	height: 35px;
	border-left: 1px solid #e5e5e5;
	content: "";
}

/*** 

====================================================================
	Header Style Two
====================================================================

***/

.header-style-two{
	position: relative;
}

.header-style-two .header-top .info-list li{
	padding:11px 0px;
}

.header-style-two .header-top .top-right .social-icons{
	padding:13px 0px;
}

.header-style-two .header-lower .logo-outer{
	position:relative;
	float:left;
	padding:11px 0px;
}

.header-style-two .header-lower .logo-outer .logo{
	position:relative;
	z-index:1;
}

.header-style-two .header-lower .logo-outer:before{
	position:absolute;
	content:'';
	right:-110px;
	top:0px;
	width:2000px;
	height:100%;
	background-size:cover;
	background:url('../images/background/3.jpg') repeat;
}

.header-style-two .nav-outer{
	width:auto;
}

.header-style-two .main-menu .navigation > li{
	padding:31px 0px;
}

/* .header-style-two .main-menu,
.header-style-two .nav-outer{
	width: 100%;
} 

.header-style-two .main-menu .navbar-collapse{
	display: block !important;
}

.header-style-two .header-upper .logo-outer{
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
}*/

/* .header-style-two .header-upper .logo-outer:before{
	display: none;
}

.header-style-two .header-upper .logo:before{
	background-color: #ffffff;
}

.header-style-two .header-top .top-left{
	padding-left: 0;
}

.header-style-two .main-menu .navigation > li{
	margin-left: 0;
	margin-right: 90px;
}

.header-style-two .main-menu .navigation > li:last-child{
	margin-right: 0;
}

.header-style-two .header-top .top-right{
	padding: 10px 0;
}

.header-style-two .social-icon-two li{
	margin-left: 35px;
}

.header-style-two .social-icon-two li a{
	font-size: 15px;
	color: #c8bfe3;
}

.header-style-two .social-icon-two li a:hover{
	color: #ff7d00;
} */

/*** 

====================================================================
				Sticky Header
====================================================================

***/

.sticky-header{
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 10px 0px;
    z-index: -1;
    background: #000000b0;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -ms-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -o-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -webkit-transition: top 100ms linear;
    -moz-transition: top 100ms linear;
    -ms-transition: top 100ms linear;
    -o-transition: top 100ms linear;
    transition: top 100ms linear;
}

.fixed-header .sticky-header{
	opacity:1;
	z-index: 99999;
	visibility:visible;
}

.fixed-header .sticky-header .logo{
	padding: 0px 0px;
}

.sticky-header .main-menu .navigation,
.sticky-header .main-menu .navigation > li{
	float: left;
}

.sticky-header .main-menu .navigation > li{
	margin-left: 32px !important;
	padding: 0;
	margin-right: 0;
	background-color: transparent;
}

.sticky-header .main-menu .navigation > li.dropdown > a:after,
.sticky-header .main-menu .navigation > li:before{
	display: none;
}

.sticky-header .main-menu .navigation > li > a{
	padding:20px 0px !important;
	line-height:30px;
	font-size:17px;
	font-weight:500;
	color:#ffffff;
	text-transform:capitalize;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li.current-menu-item > a{
	opacity:1;
	color: #ff7d00;
}

/*** 

====================================================================
			Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler{
	position: relative;
	float: right;
	font-size: 40px;
	line-height: 50px;
	cursor: pointer;
	color:#ff7d00;
	display: none;
}

.mobile-menu{
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right:30px;
	max-width:100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .navbar-collapse{
	display:block !important;	
}

.mobile-menu .nav-logo{
	position:relative;
	padding:30px 25px;
	text-align:left;	
}

.mobile-menu-visible{
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-backdrop{
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background:#000000;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
	opacity: 0.70;
	visibility: visible;
	-webkit-transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
	transition:all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .menu-box{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #202020;
	padding: 0px 0px;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	border-radius: 0px;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box{
	opacity: 1;
	visibility: visible;
	-webkit-transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
	transition:all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .close-btn{
	position: absolute;
	right: 10px;
	top: 10px;
	line-height: 30px;
	width: 24px;
	text-align: center;
	font-size: 16px;
	color: #ffffff;
	cursor: pointer;
	z-index: 10;
	-webkit-transition:all 0.9s ease;
	-moz-transition:all 0.9s ease;
	-ms-transition:all 0.9s ease;
	-o-transition:all 0.9s ease;
	transition:all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn{
	-webkit-transform:rotate(360deg);
	-ms-transform:rotate(360deg);
	transform:rotate(360deg);
}

.mobile-menu .close-btn:hover{
	color:#ff7d00;
	-webkit-transform:rotate(90deg);
	-ms-transform:rotate(90deg);
	transform:rotate(90deg);
}

.mobile-menu .navigation{
	position: relative;
	display: block;
	width: 100%;
	float: none;
}

.mobile-menu .navigation li{
	position: relative;
	display: block;
	border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation:last-child{
	border-bottom: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 25px;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	text-transform: uppercase;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;	
}

.mobile-menu .navigation li ul li > a{
	font-size: 16px;
	margin-left: 20px;
	text-transform: capitalize;
}

.mobile-menu .navigation li > a:before{
	content:'';
	position:absolute;
	left:0;
	top:0;
	height:0;
	border-left:5px solid #ff7d00;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;	
}

.mobile-menu .navigation li.current > a,
.mobile-menu .navigation li > a:hover{
	color:#ff7d00;	
}

.mobile-menu .navigation li.current > a:before{
	height:100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:6px;
	top:6px;
	width:32px;
	height:32px;
	text-align:center;
	font-size:16px;
	line-height:32px;
	color:#ffffff;
	background:rgba(255,255,255,0.10);
	cursor:pointer;
	border-radius:2px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;	
	z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open{
	background:#ff7d00;	
	-webkit-transform:rotate(90deg);
	-ms-transform:rotate(90deg);
	transform:rotate(90deg);	
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.mobile-menu .social-links{
	position:relative;
	text-align:center;
	padding:30px 25px;
}

.mobile-menu .social-links li{
	position:relative;
	display:inline-block;
	margin:0px 10px 10px;
}

.mobile-menu .social-links li a{
	position:relative;
	line-height:32px;
	font-size:16px;
	color:#ffffff;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;	
}

.mobile-menu .social-links li a:hover{
	color:#ff7d00;	
}


/*** 

====================================================================
		Section Title
====================================================================

***/

.sec-title{
	position:relative;
	z-index: 1;
	margin-bottom:60px;
}

.sec-title .title{
	position: relative;
	display: block;
	font-size: 20px;
	color: #ff7d00;
	font-weight: 400;
	margin-bottom: 10px;
	letter-spacing:1px;
	text-transform: uppercase;
}

.sec-title h2{
	position: relative;
	display: block;
	font-size:60px;
	line-height: 1.1em;
	color: #ffffff;
	font-weight: 700;
	text-transform:uppercase;
}

.sec-title.light h2{
	color: #ffffff;
}

/*** 

====================================================================
		Banner Section
====================================================================

***/

.banner-section{
	position: relative;
}

.banner-carousel{
	position: relative;
}

.banner-carousel .slide-item{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 230px 0px;
}

.banner-section.style-two .slide-item{
	padding: 170px 0px 200px;
}

.banner-carousel .slide-item .image-layer{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
	-webkit-transition: all 15000ms linear;
	-moz-transition: all 15000ms linear;
	-ms-transition: all 15000ms linear;
	-o-transition: all 15000ms linear;
	transition: all 15000ms linear;
}

.banner-carousel .active .slide-item .image-layer{
	-webkit-transform:scale(1.15);
	-ms-transform:scale(1.15);
	transform:scale(1.15);
}

.banner-carousel .slide-item:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	/* background-color: #09031c; */
	opacity: .50;
	content: "";
	z-index:1;
}

.banner-carousel .content-box{
	position: relative;
	max-width: 760px;
	margin: 0 auto;
	text-align: center;
	padding: 0 0px;
	z-index:5;
}

.banner-section.style-two .content-box{
	max-width:100%;
	text-align:left;
	padding:0px;
}

.banner-carousel .content-box h2{
	display: block;
	font-size: 130px;
	line-height: .9em;
	color: #ffffff;
	font-weight: 700;
	opacity: 0;
	margin-bottom: 30px;
	-webkit-transform: translateY(-100px);
	-moz-transform: translateY(-100px);
	-ms-transform: translateY(-100px);
	-o-transform: translateY(-100px);
	transform: translateY(-100px);
	-webkit-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	transition: all 700ms ease;
	text-transform:uppercase;
	font-family: 'Roboto Condensed', sans-serif;
}

.banner-section.style-two .content-box h2{
	margin-bottom: 20px;
}

.banner-carousel .active .content-box h2{
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-carousel .content-box .text{
	display: block;
	font-size: 24px;
	line-height: 1.6em;
	color: rgba(255,255,255,0.60);
	font-weight: 400;
	opacity: 0;
	max-width:700px;
	margin-bottom: 40px;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	transition: all 700ms ease;
	font-family: 'Roboto Condensed', sans-serif;
}

.banner-carousel .active .content-box .text{
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 700ms;
	-moz-transition-delay: 700ms;
	-ms-transition-delay: 700ms;
	-o-transition-delay: 700ms;
	transition-delay: 700ms;
}

.banner-carousel .content-box .btn-box{
	position: relative;
	opacity: 0;
	z-index: 9;
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	transition: all 700ms ease;
}

.banner-carousel .active .content-box .btn-box{
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 700ms;
	-moz-transition-delay: 700ms;
	-ms-transition-delay: 700ms;
	-o-transition-delay: 700ms;
	transition-delay: 700ms;
}

.style-two .banner-carousel .active .content-box .btn-box{
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.banner-section .owl-nav{
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width:100%;
	height:0;
	opacity:0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section:hover .owl-nav{
	opacity:1;	
}

.banner-section .owl-nav .owl-prev{
	position: absolute;
	left: 0px;
	background: #00000095;
	height: 80px;
	width: 40px;
	border-radius: 0;
	/* border: 2px solid #ffffff;  */
	text-align: center;
	line-height: 80px;
	color: #ffffff;
	font-size: 24px;
	opacity: .90;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-nav .owl-next{
	position: absolute;
	right: 0px;
	background: #00000095;
	height: 80px;
	width: 40px;
	border-radius: 0;
	/* border: 2px solid #ffffff;  */
	text-align: center;
	line-height: 80px;
	color: #ffffff;
	font-size: 24px;
	opacity: .90;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section .owl-nav .owl-prev:hover, 
.banner-section .owl-nav .owl-next:hover{
	opacity: 1;
}

.banner-section .owl-dots{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 57px;
	text-align: center;
	padding-top: 20px;
	border-bottom: 13px solid #000000;
	/* background:url(../images/icons/pagers-curve.png) center top no-repeat; */
}

.banner-section .owl-dots .owl-dot{
	position: relative;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin: 0px 4px;
}

.banner-section .owl-dots .owl-dot span{
	position: relative;
	display: block;
	width: 12px;
	height: 12px;
	background: #4d4d4d;
	border: 1px solid transparent;
	border-radius: 50%;
}

.banner-section .owl-dots .owl-dot.active span{
	background: #ff7d00;
	border-color: #ff7d00;
}

.banner-section.style-two .owl-dots{
	bottom: 40px;
	height: auto;
	padding: 0;
	border: none;
	background:none;
}

/*** 

====================================================================
	Welcome Section
====================================================================

***/

.welcome-section{
	position:relative;
	padding:115px 0px 115px;
}

.welcome-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	height:213px;
	background:url('../images/background/1.png') no-repeat;
}

/* Default Portfolio Item */

.default-portfolio-item{
	position:relative;
	margin-bottom:30px;
}

.default-portfolio-item .inner-box{
	position:relative;
	width:100%;
	overflow:hidden;
}

.default-portfolio-item .image-box{
	position:relative;
	display:block;	
	margin-bottom:0px;
}

.default-portfolio-item .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.default-portfolio-item .overlay-box{
	position:absolute;
	left:1px;
	top:1px;
	right:1px;
	bottom:1px;
	padding:0px;
	color:#ffffff;
	opacity:0;
	text-align:center;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transform:scaleX(0);
	-ms-transform:scaleX(0);
	-o-transform:scaleX(0);
	-moz-transform:scaleX(0);
	transform:scaleX(0);
}

.default-portfolio-item .inner-box:hover .overlay-box{
	top:0px;
	opacity:1;
	-webkit-transform:scaleX(1);
	-ms-transform:scaleX(1);
	-o-transform:scaleX(1);
	-moz-transform:scaleX(1);
	transform:scaleX(1);	
}

.default-portfolio-item .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:10px 20px;
	background:rgba(0,0,0,0.70);
}

.default-portfolio-item .overlay-inner .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.default-portfolio-item .overlay-inner h3{
	position:relative;
	color:#ffffff;
	font-size:40px;
	line-height:1.2em;
	font-weight:700;
	text-transform:uppercase;
}

.default-portfolio-item .overlay-inner h3 a{
	position:relative;

	color:#ffffff;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.default-portfolio-item .overlay-inner h3 a:hover{
	color:#ff7d00;
}

.welcome-section .lower-box{
	position:relative;
	padding-top:30px;
	text-align:center;
}

.welcome-section .lower-box .text{
	position:relative;
	color:#848484;
	font-size:20px;
	max-width:780px;
	margin:0 auto;
	line-height:1.7em;
	margin-bottom:40px;
}

/*** 

====================================================================
	Gallery Section
====================================================================

***/

.gallery-section{
	position:relative;
	padding:105px 0px 90px;
	background-size:cover;
	background-repeat:no-repeat;
}

.gallery-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-color:rgba(0,0,0,0.75);
}

/* Gallery Block */

.gallery-block{
	position:relative;
}

.gallery-block .inner-box{
	position:relative;
	border:1px solid #26292e;
}

.gallery-block .inner-box .image{
	position:relative;
}

.gallery-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.gallery-block .inner-box .image .overlay-box{
	position:absolute;
	left:58px;
	top:58px;
	right:58px;
	bottom:58px;
}

.gallery-block .inner-box .image .overlay-box .overlay-inner{
	position:static;
	height:100%;
}

.gallery-block .inner-box .image .overlay-box .overlay-inner .content{
	position:absolute;
	left:0px;
	bottom:0px;
}

.gallery-block .inner-box .image .overlay-box .title{
	position:relative;
	color:#ff7d00;
	font-size:16px;
	text-transform:uppercase;
	letter-spacing:1px;
}

.gallery-block .inner-box .image .overlay-box h2{
	position:relative;
	color:#ffffff;
	font-size:40px;
	line-height:1.1em;
	font-weight:700;
	text-transform:uppercase;
}

.gallery-block .inner-box .image .overlay-box h2 a{
	position:relative;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.gallery-block .inner-box .image .overlay-box h2 a:hover{
	color:#ff7d00;
}

.gallery-block .inner-box .image .overlay-box .play-box{
	position:relative;
	left:0px;
	top:0px;
	width:72px;
	height: 72px;
	text-align:center;
	line-height: 70px;
	display:inline-block;
	box-shadow:inset 0 0 45px rgba(0,0,0,0.40);
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.gallery-block .inner-box .image .overlay-box .play-box span{
	position: relative;
	width:72px;
	height: 72px;
	z-index:99;
	color: #ffffff;
	font-weight:400;
	font-size:20px;
	text-align: center;
	padding-left:4px;
	display: inline-block;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	background-color:#ff7d00;
}

.gallery-block .inner-box .image .overlay-box .play-box .ripple,
.gallery-block .inner-box .image .overlay-box .play-box .ripple:before,
.gallery-block .inner-box .image .overlay-box .play-box .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width:72px;
	height: 72px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.gallery-block .inner-box .image .overlay-box .play-box .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.gallery-block .inner-box .image .overlay-box .play-box .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

/* Gallery Block Two */

.gallery-block-two{
	position:relative;
	margin-bottom:30px;
}

.gallery-block-two .inner-box{
	position:relative;
	/* border:1px solid #26292e; */
}

.gallery-block-two .inner-box .image:before{
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	bottom: -2px;
	right: -2px;
	border: 2px solid transparent;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.gallery-block-two .inner-box .image{
	position:relative;
	z-index: 1;
}

.gallery-block-two .inner-box:hover .image:before{
	border-color: #ff7d00;
}

.gallery-block-two .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.gallery-block-two .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
}

.gallery-block-two .inner-box .image .overlay-box .overlay-link{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	display:block;
	z-index:1;
}

.gallery-block-two .inner-box .image .overlay-box h3{
	position:absolute;
	left:0px;
	bottom:0px;
	color:#ffffff;
	font-size:24px;
	font-weight:700;
	letter-spacing:1px;
	display:inline-block;
	text-transform:uppercase;
	padding:25px 55px;
	line-height:30px;
	background-color:#ff7d00;
	/* background-color:rgba(55,134,255,0.80); */
}

.gallery-block-two .inner-box .image .overlay-box h3 .icon{
	position:relative;
	font-size:20px;
	top:-1px;
	color:#ffffff;
	margin-right:10px;
	display:inline-block;
}

/*** 

====================================================================
	Matches Section
====================================================================

***/

.matches-section{
	position:relative;
	padding:50px 0px 50px;
}

.matches-section .matches-tabs{
	position:relative;
	border:1px solid #2e2d2d;
	border-top:none;
}

.matches-section .matches-tabs .tab-btns{
	position:relative;
	z-index:1;
	border:1px solid #2e2d2d;
	background:url('../images/background/3.jpg') no-repeat;
	background-size:cover;
	border-right:none;
}

.matches-section .matches-tabs .tab-btns .tab-btn{
	position:relative;
	left:-1px;
	display:block;
	float:left;
	margin-right:1px;
	font-size:16px;
	background:none;
	color:#848484;
	font-weight:700;
	line-height:24px;
	cursor:pointer;
	letter-spacing:1px;
	padding:18px 58px 18px;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	transition:all 500ms ease;
	text-transform:uppercase;
}

.matches-section .matches-tabs .tab-btns .tab-btn span{
	position:relative;
	z-index:1;	
}

.matches-section .matches-tabs .tab-btns .tab-btn:before{
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:0;
	height:100%;
	background:#ff7d00;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	transition:all 500ms ease;	
}

.matches-section .matches-tabs .tab-btns .tab-btn:hover:before,
.matches-section .matches-tabs .tab-btns .tab-btn.active-btn:before{
	width:100%;	
}

.matches-section .matches-tabs .tab-btns .tab-btn:hover,
.matches-section .matches-tabs .tab-btns .tab-btn.active-btn{
	color:#ffffff;
}

.matches-section .matches-tabs .tabs-content{
	position:relative;
}

.matches-section .matches-tabs .tabs-content .tab{
	position:relative;
	display:none;
	border-top:0px;
}

.matches-section .matches-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.matches-section .matches-tabs .matches-block{
	border-bottom:1px solid #302f2f;
}

.matches-section .matches-tabs .matches-block:last-child{
	border-bottom:0px;
}

/* Matches Block */

.matches-block{
	position:relative;
}

.matches-block .inner-block{
	position:relative;
	padding:55px 50px;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.matches-block .inner-block:hover{
	background-color:#000000;
}

.matches-block .inner-block .content-column{
	position:relative;
}

.matches-block .inner-block .content-column .inner-column{
	position:relative;
}

.matches-block .inner-block .content-column ul{
	position:relative;
}

.matches-block .inner-block .content-column ul li{
	position:relative;
	color:#848484;
	font-size:14px;
	margin-right:7px;
	margin-bottom:8px;
	padding:9px 35px 7px;
	display:inline-block;
	text-transform:uppercase;
	border:1px solid #2e2d2d;
}

.matches-block .inner-block .content-column .title{
	position:relative;
	color:#ff7d00;
	font-size:16px;
	margin-top:18px;
	letter-spacing:1px;
	text-transform:uppercase;
}

.matches-block .inner-block .content-column .title span{
	position:relative;
	letter-spacing:2px;
	margin-left:10px;
	color:#ffffff;
}

.matches-block .inner-block .content-column h2{
	position:relative;
	color:#ffffff;
	font-size:30px;
	margin-top:15px;
	font-weight:700;
	text-transform:uppercase;
}

.matches-block .inner-block .content-column h2 a{
	position:relative;
	color:#ffffff;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.matches-block .inner-block .content-column h2 a:hover{
	color:#ff7d00;
}

.matches-block .inner-block .content-column .date{
	position:relative;
	color:#848484;
	font-size:16px;
	margin-top:15px;
	font-weight:400;
	text-transform:uppercase;
}

.matches-block .match-column{
	position:relative;
}

.matches-block .match-column .inner-column{
	position:relative;
	padding-left:50px;
}

.matches-block .match-column .row{
	margin:0px -50px;
}

.matches-block .match-column .match-item{
	padding:0px 50px;
}

.matches-block .match-column .match-item:first-child::before{
	position:absolute;
	top:50%;
	right:-20px;
	/* content:'vs'; */
	width:40px;
	height:40px;
	color:#ffffff;
	font-size:14px;
	line-height:38px;
	text-align:center;
	margin-top:-30px;
	display:inline-block;
	background-color:#000000;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.matches-block:hover .match-column .match-item::before,
.matches-block .match-column:hover .match-item::before{
	color:#ff7d00;
	border-color:#ff7d00;
	background-color:#ff7d00;
}

.matches-block:hover .match-item .inner-item .product{
	background:#0c0a0a;	
}

/* Match Item */

.match-item{
	position:relative;
}

.match-item .inner-item{
	position:relative;
}

.match-item .inner-item .icon-box{
	position:relative;
	text-align:center;
	font-size:70px;
	color:#ffffff;
	line-height:1em;
	padding:30px 0px;
	background:url('../images/background/4.jpg') no-repeat;
	background-size:cover;
}

.match-item .inner-item .icon-box .icon{
	position:relative;
}

.match-item .inner-item .product{
	position:relative;
	color:#848484;
	display:block;
	font-size:14px;
	text-align:center;
	margin-top:10px;
	padding:6px 0px;
	background:#000000;
	text-transform:uppercase;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.match-item .inner-item .product:hover{
	color:#ffffff;
	border-color:#ff7d00;
	background-color:#ff7d00;
}

.gallery-section-two .outer-container{
	position:relative;
	padding:0px 35px;
}

/*Gallery Item*/

.gallery-item{
	position:relative;
	margin-bottom:30px;
}

.gallery-item .inner-box{
	position:relative;
	width:100%;
	overflow:hidden;
}

.gallery-item .image-box{
	position:relative;
	display:block;
	margin:0px;
}

.gallery-item .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.gallery-item .overlay-box{
	position:absolute;
	left:1px;
	top:1px;
	right:1px;
	bottom:1px;
	opacity:0;
	color:#ffffff;
	text-align:center;
	 transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    background:rgba(0,0,0,0.70);
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
	-moz-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
	transform: scaleY(0);
}

.gallery-item .inner-box:hover .overlay-box{
	opacity:1;
	-moz-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
}

.gallery-item .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:10px 0px;
}

.gallery-item .overlay-inner .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.gallery-item .overlay-inner .link{
	position:relative;
	width:50px;
	height:50px;
	margin:0px 5px;
	font-weight:400;
	text-align:center;
	display:inline-block;
	opacity: 0;
}

.gallery-item .overlay-inner .link .icon{
	position:relative;
	display: block;
	width:50px;
	height:50px;
	font-size:18px;
	color:#ffffff;
	line-height:50px;
	font-weight:400;
	text-align:center;
	background-color:#ff7d00;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	z-index: 1;
}

.gallery-item .inner-box .overlay-inner .link:nth-child(1){
	top: -40px;
}

.gallery-item .inner-box .overlay-inner .link:nth-child(2){
	top: 40px;
}

.gallery-item .inner-box:hover .overlay-inner .link{
	top: 0;
	opacity: 1;
	transition:all 500ms ease 600ms;
	-webkit-transition:all 500ms ease 600ms;
	-ms-transition:all 500ms ease 600ms;
	-o-transition:all 500ms ease 600ms;
}

.gallery-item .inner-box:hover .overlay-inner .link{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.gallery-item .overlay-inner .link .icon{
	position:relative;
}

.gallery-item .overlay-inner .link:hover .icon{
	color:#ff7d00;
	background-color:#ffffff;
}

/*** 

====================================================================
	Questions Section
====================================================================

***/

.questions-section{
	position:relative;
	padding-top:70px;
}

.questions-section .inner-container{
	position:relative;
}

.questions-section .inner-container:before{
	position:absolute;
	content:'';
	left:50%;
	top:0px;
	width:1px;
	bottom:100px;
	background-color:#302f2f;
}

.questions-section .inner-container:after{
	position:absolute;
	content:'';
	left:0px;
	top:50%;
	width:100%;
	height:1px;
	margin-top:-50px;
	background-color:#302f2f;
}

.questions-section .inner-container .row{
	margin:0px -60px;
}

.questions-section .inner-container .question-block{
	padding:0px 60px;
}

.questions-section .inner-container .question-block:nth-child(3),
.questions-section .inner-container .question-block:nth-child(4){
	border-bottom:0px;
	margin-bottom:0px;
}

.question-block{
	position:relative;
	margin-bottom:90px;
}

.question-block .inner-box{
	position:relative;
	padding-left:135px;
	padding-bottom:90px;
}

.question-block .inner-box .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:94px;
	height:94px;
	color:#ffffff;
	font-size:40px;
	line-height:92px;
	text-align:center;
	border:1px solid #302f2f;
	background:url('../images/background/4.jpg') no-repeat;
	background-size:cover;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.question-block .inner-box .icon-box .icon{
	position:relative;
}

.question-block .inner-box:hover .icon-box{
	color:#ff7d00;
}

.question-block .inner-box h3{
	position:relative;
	font-size:20px;
	color:#ffffff;
	text-transform:uppercase;
}

.question-block .inner-box h3 a{
	position:relative;
	color:#ffffff;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.question-block .inner-box:hover h3 a{
	color:#ff7d00;
}

.question-block .inner-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.9em;
	margin-top:30px;
}

.call-to-action-section{
	position:relative;
}

/*** 

====================================================================
	Call To Action Section
====================================================================

***/

.facts-section{
	position:relative;
}

.facts-section .title-boxed{
	position:relative;
	background-size:cover;
	background-position:center center;
	background-attachment:fixed;
	padding:105px 0px 105px;
}

.facts-section .title-boxed:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(0, 0, 0, 0.70);
}

.facts-section .title-boxed .content{
	position:relative;
	max-width:660px;
}

.facts-section .content h2{
	position:relative;
	color:#ffffff;
	font-size:60px;
	font-weight:700;
	line-height:1.1em;
	letter-spacing:1px;
	margin-bottom:60px;
	text-transform:uppercase;
}

.facts-section .lower-boxed{
	position:relative;
}

.facts-section .lower-boxed .counter-column{
	position:relative;
	margin-bottom:40px;
}

.facts-section .lower-boxed .counter-column .inner-column{
	position:relative;
	padding-top:100px;
	padding-right:100px;
}

/* Featured Block */

.featured-block{
	position:relative;
}

.featured-block .inner-box{
	position:relative;
	padding-left:100px;
	padding-bottom:50px;
	margin-bottom:50px;
	border-bottom:1px solid #302f2f;
}

.featured-block .inner-box .icon-box{
	position:absolute;
	left:0px;
	top:14px;
	color:#ff7d00;
	font-size:60px;
	line-height:1em;
}

.featured-block .inner-box h3{
	position:relative;
	color:#ffffff;
	font-size:50px;
	font-weight:400;
	letter-spacing:1px;
}

.featured-block .inner-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	margin-top:4px;
}

.featured-block:last-child .inner-box{
	margin-bottom:0px;
}

.facts-section .lower-boxed .testimonial-column{
	position:relative;
	margin-bottom:40px;
}

.facts-section .lower-boxed .testimonial-column .inner-column{
	position:relative;
	padding-top:100px;
	padding-left:150px;
	margin-top:-220px;
	padding-bottom:110px;
}

.facts-section .lower-boxed .testimonial-column .inner-column:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:20000px;
	height:100%;
	background:#030321;
	/* background: #030321 url('../images/background/bg.png') repeat-y; */
}

.facts-section .lower-boxed .testimonial-column .owl-nav{
	display:none;
}

.facts-section .lower-boxed .testimonial-column .owl-dots{
	position:absolute;
	right:0px;
	bottom:0px;
}

.facts-section .lower-boxed .testimonial-column .owl-dots .owl-dot{
	width:11px;
	height:11px;
	margin-left:9px;
	border-radius:50px;
	display:inline-block;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
	background-color:#4d4d4d;
	
}

.facts-section .lower-boxed .testimonial-column .owl-dots .owl-dot:hover,
.facts-section .lower-boxed .testimonial-column .owl-dots .owl-dot.active{
	background-color:#ff7d00;
}

/* Testimonial Block */

.testimonial-block{
	position:relative;
}

.testimonial-block .inner-box{
	position:relative;
}

.testimonial-block .inner-box .quote-icon{
	position:relative;
	left:1px;
	color:#ff7d00;
	font-size:38px;
	line-height:1em;
}

.testimonial-block .inner-box .text{
	position:relative;
	color:#ffffff;
	font-size:30px;
	line-height:1.5em;
	margin-top:35px;
}

.testimonial-block .inner-box .author{
	position:relative;
	color:#ff7d00;
	font-size:18px;
	letter-spacing:2px;
	text-transform:uppercase;
	margin-top:40px;
	
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section{
	position:relative;
	padding:70px 0px 80px;
}

.news-block{
	position:relative;
	margin-bottom:30px;
}

.news-block .inner-box{
	position:relative;
	display: block;
}

.news-block .inner-box .image{
	position:relative;
	display:block;
	overflow:hidden;
}

.news-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.news-block .inner-box:hover .image img{
	opacity:0.70;
	-webkit-transform:scale(1.05);
	-ms-transform:scale(1.05);
	transform:scale(1.05);	
}

.news-block .inner-box .lower-content{
	position:relative;
	margin-top:10px;
	padding:55px 50px;
	background-color:#000000;
}

.news-block .inner-box .lower-content .post-date{
	position:relative;
	color:#ff7d00;
	font-size:14px;
	z-index:1;
	padding:7px 25px;
	line-height:24px;
	letter-spacing:1px;
	display:inline-block;
	background-color:#ffffff;
	text-transform:uppercase;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.news-block .inner-box:hover .lower-content .post-date{
	background-color:#ff7d00;
	color:#ffffff;
}

.news-block .inner-box .lower-content h3{
	position:relative;
	font-size:24px;
	color:#ffffff;
	font-weight:700;
	line-height:1.4em;
	letter-spacing:2px;
	margin-top:22px;
	text-transform:uppercase;
}

.news-block .inner-box .lower-content h3 a{
	position:relative;
	color:#ffffff;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-content h3 a:hover{
	color:#ff7d00;
}

/*** 

====================================================================
	Sponsors Section
====================================================================

***/

.sponsors-section{
	position:relative;
	padding:90px 0px 90px;
	border-top:1px solid #302f2f;
}

.sponsors-section.no-border{
	border:none;
}

.sponsors-section.dark-bg{
	background:#000000;
	border:none;
}

.sponsors-section .sponsors-outer{
	position:relative;
}

.sponsors-section .sponsors-outer .owl-dots,
.sponsors-section .sponsors-outer .owl-nav{
	position:relative;
	display:none;
}

.sponsors-section .sponsors-outer .image-box{
	position:relative;
	text-align:center;
}

.sponsors-section .sponsors-outer .image-box img{
	max-width:100%;
	width:auto;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	opacity:0.2;
}

.sponsors-section .sponsors-outer .image-box img:hover{
	opacity:1;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
	position:relative;
	background-color:#000000;
}

.main-footer:before{
	position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    right: 0px;
    height: 213px;
    background: url(../images/background/6.png) no-repeat;
}

.main-footer .widgets-section{
	position:relative;
	padding:50px 0px 70px;
}

.main-footer .footer-widget{
	position:relative;
	margin-bottom:40px;
}

.main-footer .footer-title{
	position:relative;
	margin-bottom:30px;
}

.main-footer .footer-title h2{
	position:relative;
	font-size:16px;
	font-weight:700;
	color:#ffffff;
	letter-spacing:1px;
	text-transform:uppercase;
}

.main-footer .logo-widget{
	position:relative;
}

.main-footer .logo-widget .logo{
	position:relative;
	margin-bottom:20px;
}

.main-footer .logo-widget .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:2em;
}

.main-footer .links-widget .widget-content{
	padding-left:50px;
}

.main-footer .links-widget .list li{
	position:relative;
	margin-bottom:15px;
}

.main-footer .links-widget .list li a{
	position:relative;
	color:#848484;
	font-size:16px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.main-footer .links-widget .list li a:hover{
	color:#ff7d00;
}

.main-footer .newsletter-widget .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:2em;
	margin-bottom:25px;
}


.newsletter-form{
	position:relative;
}

.newsletter-form form{
	position:relative;
}

.newsletter-form .form-group{
    position: relative;
}

.newsletter-form .form-group input[type="text"],
.newsletter-form .form-group input[type="tel"],
.newsletter-form .form-group input[type="email"],
.newsletter-form .form-group textarea {
    position: relative;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    height: 80px;
	display:inline-block;
    font-size: 16px;
    background: none;
	font-weight:400;
	color:#ffffff;
	border:1px solid rgba(0,0,0,0);
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
	background-color:#1b1a1a;
}

.newsletter-form .form-group input[type="text"]:focus,
.newsletter-form .form-group input[type="email"]:focus,
.newsletter-form .form-group input[type="tel"]:focus{
	border-color:#ff7d00;
}

.newsletter-form .form-group input::-webkit-input-placeholder{
	color:#848484;
}

.newsletter-form .form-group .newsletter-btn {
	position: absolute;
	width:70px;
	height:80px;
	top: 0px;
	right: 0px;
	float:right;
	color:#ffffff;
	font-size:18px;
	font-weight:400;
	text-align:center;
	padding:0px;
	display:inline-block;
	background:none;
}

.main-footer .footer-bottom{
	position:relative;
	padding:25px 0px;
	border-top:1px solid #302f2f;
	background:url('../images/background/3.jpg') repeat;
}

.main-footer .footer-bottom .copyright{
	position:relative;
	color:#848484;
	font-size:15px;
}

.main-footer .footer-bottom .social-icons{
	position:relative;
	text-align:right;
}

.main-footer .footer-bottom .social-icons li{
	position:relative;
	margin-left:30px;
	display:inline-block;
}

.main-footer .footer-bottom .social-icons li a{
	position:relative;
	color:#ffffff;
	font-size:16px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .footer-bottom .social-icons li a:hover{
	color:#ff7d00;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.main-footer .footer-bottom .scroll-to-top{
	position:absolute;
	left:50%;
	top:-30px;
	width:53px;
	height:53px;
	color:#ffffff;
	font-size:20px;
	line-height:53px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	background:#ff7d00;
	border-radius: 0px;
	margin-left:-26px;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-footer .footer-bottom .scroll-to-top:hover{
	background:#ffffff;
	color:#ff7d00;
}

/*** 

====================================================================
	Games Section
====================================================================

***/

.games-section{
	position:relative;
	padding:105px 0px 80px;
}

.games-block{
	position:relative;
	margin-bottom:30px;
}

.games-block .inner-box{
	position:relative;
	display: block;
}

.games-block .inner-box:hover .btn-style-two .btn-title:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
}

.games-block .inner-box:hover .btn-style-two .btn-title{
	color:#ffffff;	
}

.games-block .inner-box:hover .btn-style-two:before{
	background:#ffffff;	
}

.games-block .inner-box .image{
	position:relative;
}

.games-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.games-block .inner-box .lower-content{
	position:relative;
	text-align:center;
	margin-top:10px;
	padding:30px 15px 35px;
	background-color:#000000;
}

.games-block .inner-box .lower-content h3{
    position: relative;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    line-height: 1.4em;
    letter-spacing: 2px;
	margin-bottom:18px;
    text-transform: uppercase;
}

.games-block .inner-box .lower-content h3 a{
	position:relative;
	color:#ffffff;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.games-block .inner-box .lower-content h3 a:hover{
	color:#ff7d00;
}

/*** 

====================================================================
	Welcome Section Two
====================================================================

***/

.welcome-section-two{
	position:relative;
	padding:0px 0px 0px;
}

.welcome-section-two .auto-container{
	position:relative;
	padding-top:110px;
}

.welcome-section-two .auto-container:before{
	position:absolute;
	content:'';
	right:0px;
	top:0px;
	width:200000px;
	height:100%;
	background-color:#000000;
}

.welcome-section-two .title-column{
	position:relative;
	margin-bottom:40px;
}

.welcome-section-two .title-column .inner-column{
	position:relative;
	z-index:1;
	padding-right:70px;
}

.welcome-section-two .title-column .sec-title{
	margin-bottom:30px;
}

.welcome-section-two .title-column .text{
	position:relative;
	color:#848484;
	font-size:20px;
	line-height:1.7em;
	margin-bottom:45px;
}

.welcome-section-two .image-column{
	position:relative;
}

.welcome-section-two .image-column .inner-column{
	position:relative;
}

.welcome-section-two .image-column .image{
	position:relative;
}

.welcome-section-two .image-column .image img{
	max-width:inherit;
}

/*** 

====================================================================
	Players Section
====================================================================

***/

.players-section{
	position:relative;
	padding-bottom:90px;
}

.players-section.style-two{
	padding-top:90px;
}

.players-page-section{
	padding:115px 0px 60px; 
}

.players-page-section .player-block{
	margin-bottom:60px;
}

/* Player Block */

.player-block{
	position:relative;
	margin-bottom:30px;
}

.player-block .inner-box{
	position:relative;
	display: block;
}

.player-block .inner-box .image{
	position:relative;
}

.player-block .inner-box:hover .image{
	border-color:#ff7d00;	
}

.player-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.player-block .inner-box .lower-content{
	position:relative;
	margin-top:10px;
	padding:30px 50px 45px;
	background-color:#000000;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.player-block .inner-box .lower-content h3{
	position:relative;
	font-size:24px;
	font-weight:700;
	line-height:1.3em;
	letter-spacing:1px;
	text-transform:uppercase;
}

.player-block .inner-box .lower-content h3 a{
	position:relative;
	color:#ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.player-block .inner-box .lower-content h3 a:hover{
	color:#ff7d00;
}

.player-block .inner-box .lower-content .level{
	position:relative;
	color:#848484;
	font-size:14px;
	letter-spacing:1px;
	margin-top:10px;
	text-transform:uppercase;
}

.player-block .inner-box .lower-content .social-icons{
	position:relative;
	padding-top:30px;
	margin-top:30px;
	border-top:1px solid #302f2f;
}

.player-block .inner-box .lower-content .social-icons li{
	position:relative;
	margin-right:30px;
	display:inline-block;
}

.player-block .inner-box .lower-content .social-icons li a{
	position:relative;
	color:#ffffff;
	font-size:16px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.player-block .inner-box .lower-content .social-icons li a:hover{
	color:#ff7d00;
}

/*** 

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action-section{
	position:relative;
	text-align:center;
	padding:115px 0px 115px;
	background-size:cover;
}

.call-to-action-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-color:rgba(0,0,0,0.70);
}

.call-to-action-section .content{
	position:relative;
}

.call-to-action-section .content .play-box{
	position:relative;
	left:0px;
	top:0px;
	width:72px;
	height: 72px;
	text-align:center;
	line-height: 70px;
	display:inline-block;
	box-shadow:inset 0 0 45px rgba(0,0,0,0.40);
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.call-to-action-section .content .play-box span{
	position: relative;
	width:72px;
	height: 72px;
	z-index:99;
	color: #ffffff;
	font-weight:400;
	font-size:20px;
	text-align: center;
	padding-left:4px;
	display: inline-block;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	background-color:#ff7d00;
}

.call-to-action-section .content .play-box .ripple,
.call-to-action-section .content .play-box .ripple:before,
.call-to-action-section .content .play-box .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width:72px;
	height: 72px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.call-to-action-section .content .play-box .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.call-to-action-section .content .play-box .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

.call-to-action-section .content h2{
	position:relative;
	color:#ffffff;
	font-size:60px;
	letter-spacing:1px;
	line-height:1.1em;
	font-weight:700;
	margin-top:50px;
	text-transform:uppercase;
}

/*** 

====================================================================
	Contact Form Section
====================================================================

***/

.contact-form-section{
	position:relative;
	padding-top:110px;
}

.contact-form-section.style-two{
	padding-bottom: 45px;
}

.contact-form-section .title-column{
	position:relative;
	margin-bottom:40px;
}

.contact-form-section .title-column .inner-column{
	position:relative;
	padding-right:65px;
}

.contact-form-section .title-column .sec-title{
	margin-bottom:25px;
}

.contact-form-section .title-column .text{
	position: relative;
    color: #848484;
    font-size: 16px;
    line-height: 1.9em;
    margin-bottom: 45px;
}

.contact-form-section .title-column .social-icons{
	position:relative;
	padding-top:30px;
	margin-top:30px;
	border-top:1px solid #302f2f;
}

.contact-form-section .title-column .social-icons li{
	position:relative;
	margin-right:30px;
	display:inline-block;
}

.contact-form-section .title-column .social-icons li a{
	position:relative;
	color:#ffffff;
	font-size:17px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-form-section .title-column .social-icons li a:hover{
	color:#ff7d00;
}

/*Default Form*/

.default-form{
	position:relative;
}

.default-form .form-group{
	position:relative;
	margin-bottom:30px;
}

.default-form input[type="text"],
.default-form input[type="email"],
.default-form input[type="password"],
.default-form input[type="url"],
.default-form input[type="tel"],
.default-form input[type="search"],
.default-form select,
.default-form textarea{
	display:block;
	width:100%;
	line-height:28px;
	height:80px;
	font-size:15px;
	padding:10px 30px;
	background:#1b1a1a;
	color:#ffffff;
	font-weight:400;
	border:1px solid rgba(255,255,255,0);
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto Condensed', sans-serif;
}

.default-form textarea{
	height:260px;
	resize:none;
	font-size:16px;
	font-weight:400;
	padding:20px 30px;
	font-family: 'Roboto Condensed', sans-serif;
}

.default-form input[type="text"]:focus,
.default-form input[type="email"]:focus,
.default-form input[type="password"]:focus,
.default-form input[type="url"]:focus,
.default-form input[type="tel"]:focus,
.default-form input[type="search"]:focus,
.default-form select:focus,
.default-form textarea:focus{
	border-color:#ff7d00;
}

.default-form input.error,
.default-form input.error,
.default-form textarea.error{
	border-color:#ff0000;
}

.default-form label.error{
	text-transform: capitalize;
	padding-top: 10px;
	display: block;
	line-height: 20px;
	font-size: 15px;
	font-weight: 500;
	color:#ffffff;
}

/*** 

====================================================================
		Page Banner
====================================================================

***/

.page-banner{
	position:relative;
	padding: 140px 0 90px;
	background-position:center top;
	background-repeat:no-repeat;
	background-size:cover;
	text-align: center;
	border-bottom: 1px solid #302f2f;
}

.page-banner .inner-container{
	position: relative;
	z-index:5;
}

.page-banner h1{
	position:relative;
	font-size:70px;
	color:#ffffff;
	line-height: 1.2em;
	font-weight: 700;
	text-transform: uppercase;
}

.page-banner .bread-crumb {
	position: relative;
	display: inline-block;
	
}

.page-banner .bread-crumb li{
	position: relative;
	float: left;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	text-transform: uppercase;
	cursor: default;
	padding-right:  15px;
	margin-right: 15px;
}

.page-banner .bread-crumb li:before{
	position: absolute;
	right: -3px;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
    content: ".";
}

.page-banner .bread-crumb li:last-child{
	padding-right: 0;
	margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before{
	display: none;
}

.page-banner .bread-crumb li a{
	color:#ffffff;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover{
	color:#ff7d00;	
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section{
	position:relative;
	background:#0c0a0a;
	padding:115px 0px 90px;
}

.about-section .images-gallery{
	position:relative;
}

.about-section .images-gallery .image{
	position:relative;
	margin-bottom:30px;
}

.about-section .images-gallery .image img{
	position:relative;
	width:100%;
	display:block;
}

.about-section .lower-content{
	position:relative;
	margin-top:30px;
}

.about-section .lower-content .column{
	position:relative;
	margin-bottom:20px;	
}

.about-section .lower-content h3{
	position:relative;
	color:#ffffff;
	font-size:40px;
	font-weight:700;
	text-transform:uppercase;
}

.about-section .lower-content p{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
}

/*** 

====================================================================
	Faq's Section
====================================================================

***/

.faq-section{
	position:relative;
	padding:100px 0px 100px;
}

.faq-section .inner-container{
	position:relative;
}

.faq-section .inner-container:before{
	position:absolute;
	content:'';
	left:50%;
	top:0px;
	width:1px;
	bottom:0px;
	background-color:#302f2f;
}

.faq-section .column{
	position:relative;
	float:left;
	padding:0px;
}

.faq-section .column .question-block-two:last-child{
	margin-bottom:0px;
	padding-bottom:0px;
	border-bottom:none;
}

/* Question Block Two */

.question-block-two{
	position:relative;
	margin-bottom:90px;
	padding-bottom:90px;
	border-bottom:1px solid #302f2f;
}

.question-block-two .inner-box{
	position:relative;
	padding-right:70px;
	padding-left:135px;
}

.question-block-two .inner-box .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:94px;
	height:94px;
	color:#ffffff;
	font-size:40px;
	line-height:92px;
	text-align:center;
	border:1px solid #302f2f;
	background:url('../images/background/4.jpg') no-repeat;
	background-size:cover;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.question-block-two .inner-box .icon-box .icon{
	position:relative;
}

.question-block-two .inner-box:hover .icon-box{
	color:#ff7d00;
}

.question-block-two .inner-box h3{
	position:relative;
	font-size:20px;
	color:#ffffff;
	text-transform:uppercase;
}

.question-block-two .inner-box h3 a{
	position:relative;
	color:#ffffff;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.question-block-two .inner-box:hover h3 a{
	color:#ff7d00;
}

.question-block-two .inner-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.9em;
	margin-top:30px;
}

.question-block-two.alternate{
	padding-left:80px;
}

.question-block-two.alternate .inner-box{
	padding-right:0px;
}





































/*** 

====================================================================
		Match Single
====================================================================

***/

.match-single{
	position: relative;
}

.match-single .title-box{
	position: relative;
	margin-bottom: 60px;
	padding-bottom: 55px;
	border-bottom: 1px solid #302f2f;
}

.match-single .title-box .recent-result{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #ff7d00;
	font-weight: 400;
	text-transform:uppercase;
	letter-spacing: 0.05em;
	
	margin-bottom: 15px;
}

.match-single .title-box .recent-result span{
	color: #ffffff;
	margin-left:10px;
}

.match-single .title-box h3{
	position: relative;
	display: block;
	font-size: 30px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	letter-spacing: 0.05em;
	margin-bottom: 18px;
}

.match-single .title-box .date{
	display: block;
	font-size: 16px;
	line-height: 1.2em;
	color: #848484;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	font-weight: 400;
}

.match-single .team-box{
	position: relative;
	padding-bottom: 30px;
	border-bottom: 1px solid #302f2f;
	margin-bottom: 55px;
}

.match-single .team-box:before{
	position:absolute;
	top:95px;
	left: 50%;
	/* content:'vs'; */
	width:70px;
	height:70px;
	color:#ffffff;
	font-size:24px;
	line-height:68px;
	text-align:center;
	background-color:#ff7d00;
	border:1px solid #ff7d00;
	margin-left: -35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.match-single .team-box .match-item{
	position: relative;
	max-width: 225px;
	width: 100%;
	margin-bottom: 30px;
}

.match-single .team-box .match-item .inner-item .icon-box{
	font-size: 90px;
	padding: 70px 0px;
}

.match-single .team-box .match-item .inner-item .product{
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	padding: 15px 0px;
}

.match-single .text-box{
	position: relative;
	border-bottom: 1px solid #302f2f;
	margin-bottom: 50px;
}

.match-single .text-box p{
	position: relative;
	font-size: 16px;
	line-height: 2em;
	color: #848484;
	font-weight: 400;
	margin-bottom: 50px;
}

.match-single .text-box .column{
	position: relative;
	margin-bottom: 50px;
}

.match-single .text-box .image{
	position: relative;
	margin-bottom: 0;
}

.match-single .text-box .image img{
	width: 100%;
	height: auto;
}

/*** 

====================================================================
		News Section Style Two
====================================================================

***/

.news-section.style-two{
	padding: 120px 0 60px;
}

.news-section.style-two .news-block{
	position: relative;
	margin-bottom: 60px;
}

/*** 

====================================================================
		Blog Detail
====================================================================

***/

.blog-detail{
	position: relative;
	border-bottom: 1px solid #302f2f;
	margin-bottom: 50px;
}

.blog-detail .image-box{
	position: relative;
}

.blog-detail .image-box .image{
	position: relative;
	margin-bottom: 0;
	border: 1px solid #302f2f;
}

.blog-detail .image-box .image img{
	display: block;
	width: 100%;
	height: auto;
}

.blog-detail .lower-content{
	position: relative;
	padding: 40px 0 50px;
}

.blog-detail .lower-content .post-date{
	position:relative;
	color:#ffffff;
	font-size:14px;
	z-index:1;
	padding:9px 30px 7px;
	letter-spacing:1px;
	display:inline-block;
	background-color:#ff7d00;
	text-transform:uppercase;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
	margin-bottom: 25px;
}

.blog-detail .lower-content h3{
	position: relative;
	font-size: 24px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 15px;
}

.blog-detail .post-info {
	position: relative;
	display: inline-block;
	margin-bottom: 30px;
}

.blog-detail .post-info li{
	position: relative;
	float: left;
	font-size: 16px;
	line-height: 30px;
	color: #ff7d00;
	font-weight: 400;
	text-transform:uppercase;
	cursor: default;
	padding-right:  15px;
	margin-right: 15px;
}

.blog-detail .post-info li:before{
	position: absolute;
	right: -3px;
	font-size: 16px;
	line-height: 30px;
	color: #ff7d00;
	font-weight: 400;
    content: ".";
}

.blog-detail .post-info li:last-child{
	padding-right: 0;
	margin-right: 0;
}

.blog-detail .post-info li:last-child:before{
	display: none;
}

.blog-detail .post-info li a{
	color:#ff7d00;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.blog-detail .post-info li a:hover{
	color:#ffffff;	
}

.blog-detail .lower-content p{
	position: relative;
	font-size: 16px;
	line-height: 32px;
	color: #848484;
	font-weight: 400;
}


/*post share options*/
.post-share-options{
	position:relative;
	margin-bottom: 50px;
}

.post-share-options .tags{
	position: relative;
	display: inline-block;
	
}

.post-share-options .tags li{
	position: relative;
	float: left;
	font-size: 16px;
	line-height: 30px;
	color: #ff7d00;
	font-weight: 400;
	text-transform: uppercase;
	cursor: default;
	letter-spacing: 1px;
	padding-right:  15px;
	margin-right: 15px;
}

.post-share-options .tags li:before{
	position: absolute;
	right: -3px;
	font-size: 16px;
	line-height: 30px;
	color: #ff7d00;
	font-weight: 400;
    content: ".";
}

.post-share-options .tags li:last-child{
	padding-right: 0;
	margin-right: 0;
}

.post-share-options .tags li:last-child:before{
	display: none;
}

.post-share-options .tags li a{
	color:#ff7d00;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-share-options .tags li a:hover{
	color:#ffffff;	
}

.post-share-options .social-links{
	position: relative;
}

.post-share-options .social-links li{
	position: relative;
	display: inline-block;
	margin-left: 35px;
}

.post-share-options .social-links li a{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-share-options .social-links li a:hover{
	color: #ff7d00;
}

.post-share-options .social-links li:first-child{
	margin-left: 0;
}

/* Author Box */

.author-box{
	position: relative;
	border: 1px solid #302f2f;
	padding: 58px 58px;
	margin-bottom: 50px;
}

.author-box .inner-box{
	position: relative;
	padding-left: 215px;
	min-height: 166px;
	padding-top: 5px;
}

.author-box .thumb{
	position: absolute;
	left: 0;
	top: 0;
	width: 160px;
}

.author-box .thumb img{
	display: block;
	width: 100%;
}

.author-box .name{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1em;
	color: #ffffff;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 40px;
}

.author-box .text{
	font-size: 16px;
	line-height: 32px;
	color: #848484;
	font-weight: 400;
}


/***

====================================================================
		Sidebar Page Container
====================================================================

***/

.sidebar-page-container{
	position:relative;
	padding:120px 0px 70px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side{
	margin-bottom:50px;
}

.sidebar-widget{
    position:relative;
    padding: 45px 50px;
    border: 1px solid #302f2f;
    margin-bottom:30px;
}

.sidebar-widget:last-child{
	margin-bottom: 0;
}

.sidebar-widget .sidebar-title{
	position: relative;
	font-size: 20px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	margin-bottom: 28px;
}


.sidebar-widget .widget-content{
	position: relative;
}

/* Sidebar Title */

.sidebar-title{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #24262d;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom:25px;
}



/*Search Box Widget*/

.sidebar .search-box{
	position: relative;
	padding: 22px 50px;
}

.sidebar .search-box .form-group{
	position:relative;
	margin:0px;	
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"]{
	position:relative;
	display:block;
	font-size:18px;
	line-height:30px;
	width:100%;
	height:50px;
	color:#ffffff;
	font-weight: 500;
	background:transparent;
	padding:10px 50px 10px 0px;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.sidebar .search-box .form-group button{
	position:absolute;
	right: 0;
	top: 0;
	height:50px;
	display:block;
	font-size:18px;
	color:#ff7d00;
	line-height:100%;
	font-weight:normal;
	background:transparent;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.sidebar .search-box .form-group button:hover{
	text-shadow: 0px 2px 0px #ff7d00;
	color:#ffffff;
}


/*Post Widget*/

.sidebar .popular-posts .post{
	position:relative;
	padding-bottom: 25px;
	border-bottom: 1px solid rgba(255,255,255,.15);
	margin-bottom: 25px;
}

.sidebar .popular-posts .post:last-child{
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0;
}

.sidebar .popular-posts .post .post-inner{
	position:relative;
	padding-left:80px;
	min-height:60px;
}

.sidebar .popular-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:60px;
}

.sidebar .popular-posts .post .post-thumb img{
	display:block;
	width:100%;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sidebar .popular-posts .post .text{
	position:relative;
	font-size:16px;
	line-height:24px;
	font-weight:700;
	color:#848484;
	text-transform: uppercase;
}

.sidebar .popular-posts .post .text a{
	color:#848484;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sidebar .popular-posts .post a:hover{
	color:#ff7d00;
}

.sidebar .popular-posts .post-info{
	position:relative;
	display: block;
	font-size:14px;
	color:#ff7d00;
	font-weight:600;
	line-height:1em;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 13px;
}

/* Blog Categories */

.blog-categories{
	position:relative;
}

.blog-categories li{
	position:relative;
	margin-bottom: 26px;
}

.blog-categories li a{
	position:relative;
	font-size:16px;
	line-height: 26px;
	color:#848484;
	display:block;
	font-weight: 400;
	letter-spacing: 1px;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.blog-categories li a:hover{
	color:#ff7d00;
}

.blog-categories li:last-child{
	margin-bottom: 0;
}

/*Popular Tags*/

.sidebar .popular-tags .widget-content{
	position: relative;
}

.sidebar .popular-tags a{
	position:relative;
	display:inline-block;
	font-size:12px;
	line-height:24px;
	padding: 3px 20px;
	color:#ff7d00;
	text-align:center;
	background:#ffffff;
	font-weight:400;
	background-color: #ffffff;
	text-transform:uppercase;
	letter-spacing: 0.04em;
	margin: 0 10px 15px 0;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar .popular-tags a:hover{
	background-color:#ff7d00;
	color:#ffffff;
}


/* Next Match Widget */

.next-match-widget{
	position: relative;
}

.next-match-widget .team-box{
	position: relative;
}

.next-match-widget .team-box:before {
    position: absolute;
    top: 30px;
    left: 50%;
    /* content: 'vs'; */
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    margin-left: -20px;
    letter-spacing: 1px;
    background-color: #000000;
    border: 1px solid #302f2f;
}

.next-match-widget .title-box .title{
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 1.2em;
	color: #ff7d00;
	font-weight: 400;
	letter-spacing: 0.03em;
	text-transform:uppercase;
	margin-bottom: 10px;
}

.next-match-widget .title-box h4{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.next-match-widget .title-box h4 a{
	color: #ffffff;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.next-match-widget .title-box h4 a:hover{
	color: #ff7d00;
}

.next-match-widget .title-box .date{
	display: block;
	font-size: 14px;
	line-height: 1.2em;
	color: #848484;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	font-weight: 400;
}

.next-match-widget .team-box .match-item {
    position: relative;
    max-width: 92px;
    width: 100%;
    margin-bottom: 30px;
}

.next-match-widget .match-item .inner-item .icon-box{
	font-size: 36px;
	padding: 30px 0 30px;
}

.next-match-widget .inner-item .product{
	font-size: 12px;
}

/* Studio Widget */

.studio-widget{
	position: relative;
	padding: 0;
}

.studio-widget .widget-content{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 390px;
}

.studio-widget .widget-content .title{
	position: absolute;
	right: 0;
	bottom: 30px;
	padding: 0 40px;
	text-align: right;
	font-size: 40px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.studio-widget .widget-content .title a{
	color: #ffffff;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.studio-widget .widget-content .title a:hover{
	color: #ff7d00;
}

/* Upcoming Widget */

.upcoming-widget{
	position: relative;
}

.upcoming-widget .upcoming-match{
	position: relative;
	padding-bottom: 30px;
	border-bottom: 1px solid #302f2f;
	margin-bottom: 30px;
}

.upcoming-widget .upcoming-match:last-child{
	margin-bottom: 0;
	border-bottom: 0;
	padding-bottom: 0;
}

.upcoming-widget .upcoming-match .inner{
	position: relative;
	padding-left: 165px;
	min-height: 57px;
}

.upcoming-widget .team-box{
	position: absolute;
	left: 0;
	top: 0;
	width: 145px;
}

.upcoming-widget .team-box:before{
	position:absolute;
	top:19px;
	left: 50%;
	/* content:'vs'; */
	width:20px;
	height:20px;
	color:#ffffff;
	font-size:10px;
	line-height:18px;
	text-align:center;
	display:inline-block;
	margin-left: -10px;
	background-color:#000000;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.upcoming-widget .upcoming-match:hover .team-box:before{
	color:#ffffff;
	border-color:#ff7d00;
	background-color:#ff7d00;
}

.upcoming-widget .match-item{
	position:relative;
}

.upcoming-widget .match-item .icon-box{
	position:relative;
	text-align:center;
	font-size:26px;
	color:#ffffff;
	width: 52px;
	height: 57px;
	line-height: 55px;
	/* background:url(../images/background/4.jpg) no-repeat; */
	background-size:cover;
}

.upcoming-widget .match-item .icon-box .icon{
    position: relative;
    text-shadow: 2px 2px 0px #ff7d00;
}

.upcoming-widget .upcoming-match .date{
	position: relative;
	display: block;
	font-size: 13px;
	line-height: 27px;
	color: #848484;
	font-weight: 400;
	text-transform: uppercase;
	top: 15px;
}

.upcoming-widget .upcoming-match .overlay-link{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

/*** 

====================================================================
		Comment Area
====================================================================

 ***/

.group-title{
	position: relative;
	margin-bottom: 50px;
}

.group-title h3{
	position: relative;
	display: inline-block;
	font-size: 30px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.comments-area{
	position:relative;
	margin-bottom: 55px;
}

.comments-area .comment-box{
	position:relative;
	padding-bottom: 60px;
	border-bottom: 1px solid #302f2f;
	margin-bottom: 60px;
}

.comments-area .comment-box:last-child{
	margin-bottom: 0;
}

.comments-area .comment{
	position:relative;
	min-height: 160px;
	padding-left: 200px;
}

.comments-area .comment-box .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width: 160px;
	margin-bottom: 20px;
	overflow: hidden;
}

.comments-area .comment-box .author-thumb img{
	width:100%;
	display:block;
}

.comments-area .comment-box .name{
	position: relative;
	display: inline-block;
	font-size:20px;
	line-height:1em;
	font-weight:400;
	color:#ffffff;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 40px;
}

.comments-area .comment-box .text{
	font-size: 16px;
	line-height: 32px;
	color:#848484;
	font-weight: 400;
	padding-right: 30px;
}

.comments-area .comment-box .reply-btn{
	position: absolute;
	right: 0;
	top: 0px;
	font-size: 12px;
	line-height: 25px;
	color: #ffffff;
	font-weight: 700;
	padding: 5px 20px;
	text-transform: uppercase;
	background-color: #ff7d00;
	letter-spacing: 1px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.comments-area .comment-box .reply-btn:hover{
	background-color: #ffffff;
	color: #ff7d00;
}

/*** 

====================================================================
		Comment Form
====================================================================

 ***/

.comment-form{
 	position: relative;
}

.comment-form .form-group{
	position:relative;
	margin-bottom: 30px;
}

.comment-form .form-group:last-child{
	margin-bottom: 0;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="email"],
.comment-form .form-group textarea,
.comment-form .form-group select{
	position: relative;
    display: block;
    height: 80px;
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 28px;
    font-weight: 500;
    padding: 25px 30px;
    border: 1px solid rgba(0,0,0,0);
    background: #1b1a1a;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.comment-form .form-group input:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus{
	border-color:#ff7d00;
}

.comment-form .form-group textarea{
	height: 260px;
	resize: none;
}

.comment-form .form-group .btn-title{
	padding: 25px 63px;
	letter-spacing: 0.1em;
}

.comment-form ::-webkit-input-placeholder{color: #6c6d70;}
.comment-form ::-moz-input-placeholder{color: #6c6d70;}
.comment-form ::-ms-input-placeholder{color: #6c6d70;}

/*** 

====================================================================
		Player Info Section
====================================================================

 ***/

.player-info-section{
 	position: relative;
	padding:120px 0px 90px;
}

.player-info-section .image-column{
	position:relative;
	margin-bottom:30px;	
}

.player-info-section .image-column .inner{
	position:relative;
	display:block;	
}

.player-info-section .text-column{
	position:relative;
	margin-bottom:30px;
	order:12;
}

.player-info-section .text-column .inner{
	position:relative;
	display:block;
	padding-left: 30px;
	padding-top:70px;	
}

.player-info-section .text-column .title-box{
	position:relative;
	margin-bottom:40px;
}

.player-info-section .text-column .title-box .user-title{
	position:relative;
	font-size: 24px;
	font-weight: 700;
	color: #ffffff;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.player-info-section .text-column .title-box .user-info{
	position:relative;
	font-size: 20px;
	text-transform:uppercase;
	color: #ff7d00;
}

.player-info-section .text-column .text{
	position:relative;
	color: #848484;
	margin-bottom: 50px;
}

.player-info-section .social-icons{
	position:relative;
	padding-top: 50px;
	border-top: 1px solid rgba(255,255,255,0.20);
}

.player-info-section .social-icons li{
	position:relative;
	margin-right:28px;
	display:inline-block;
}

.player-info-section .social-icons li a{
	position:relative;
	color:#ffffff;
	font-size:16px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.player-info-section .social-icons li a:hover{
	color:#ff7d00;
}


/*** 

====================================================================
	Awards Section
====================================================================

***/

.awards-section{
	position:relative;
	padding:120px 0px;
	border-top: 1px solid rgba(255,255,255,0.20);
}

.awards-section .awards-outer{
	position:relative;
	max-width: 870px;
	margin: 0 auto;
}

.awards-section .awards-outer .owl-dots,
.awards-section .awards-outer .owl-nav{
	position:relative;
	display:none;
}

.awards-section .awards-outer .image-box{
	position:relative;
	text-align:center;
}

.awards-section .awards-outer .image-box img{
	max-width:100%;
	width:auto;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	opacity:1;
}

.awards-section .awards-outer .image-box img:hover{
	opacity:0.80;
}

/*** 

====================================================================
	Gallery Section Three
====================================================================

***/

.gallery-section-three{
	position:relative;
	padding:120px 0px 90px;
}

/*** 

====================================================================
	Map Section Style
====================================================================

***/

.map-section{
	position:relative;
	/* padding: 0px 0px 50px; */
}

.map-data{
	text-align:center;
	font-size:14px;
	font-weight:400;
	line-height:1.8em;
}

.map-data h6{
	font-size:16px;
	font-weight:700;
	text-align:center;
	margin-bottom:5px;
	color:#121212;
}

.map-canvas{
	height:450px;
}


.game-logo
{
	border-radius: 5%;
	border: 2px solid #ccc;
}





  
  .navbar-brand{
	  padding: 0px 15px;
	}
	
	
	.video {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  display: none;
	  border-radius: 10px;
	}
	
	.thumbnail:hover .video {
	  display: block;
	  
	}
  
	.text {
		line-height: 1;
	  font-size: 15px;
	  position: absolute;
	  font-weight: bolder;
	  bottom: 10px; /* Adjust as needed */
	  left: 10px; /* Adjust as needed */
	  color: white; /* Example text color */
	  padding: 5px 10px; /* Adjust as needed */
	  z-index: 2; /* Ensure the text is above the video */
	}
  
	.header-title{
	  font-family: 'Gameon';
	  font-size: 30px;
	  margin-top: 10px;
	  /* color: rgb(243, 160, 6); */
	}
  
	.logo-aligned {
	  display: flex;
	  align-items: center;
  }
  .game_name_footer{
	color: #fff;
	font-size: 18px;
	font-weight: bolder
  }
  /* span {
	  padding: 10px;
  } */
  
  
  .thumbnail {
	position: relative;
	background-color: transparent;
	/* border: 2px solid #818181; */
	border-radius: 5px;
	padding: 2px;
	margin: 0px;
  }
  .thumbnail img, .thumbnail video {
	width: 100%;
	display: block;
	border-radius: 4px;
  }
  .thumbnail video {
	display: none;
	background: rgb(0,0,0);
	background: linear-gradient(0deg, rgba(0,0,0,0.8996848739495799) 0%, rgba(0,212,255,0) 100%);
  }

  .about-content-div > ul,li{
	list-style: unset;
	font-size: 25px;
  }

  .about-section .lower-content p {
	font-size: 20px;
  }

  .player-block .lower-content
  {
	border: 1px solid #ccc;
  }


  @font-face {
	font-family: KalamReg;
	src: url('../fonts/Kalam-Regular.ttf');
  }

  .game-name:hover{
	color:'#ff7d00';
  }


  

  
  .d-item-txt {
	font-size: 55px;
	line-height: 100px;
	padding-right: 8px;
	font-style: normal;
	font-weight: bold;
	font-family: "Prompt", Helvetica, Arial, sans-serif;
	/* color: var(--primary-color); */
	user-select: none;
	text-transform:uppercase;
	 color: transparent;
	-webkit-text-stroke: 1px rgba(255, 255, 255, .75);
  }
  
  
  .d-item-display {
	width: 80px;
	height: 5px;
	margin: 0 20px;
	margin-right: 40px;
	padding: 0px;
	border: '5px solid #ccc';
	/* border-radius: 50%; */
	/* display: inline-block; */
	/* transform: translateY(-20px); */
	/* background:"#0072ff"; */
  }
  /* marquee */

  .white_font{
	color: #fff;
  }


.game_footer_text{
	font-size: 15px;
	color: #fff;
	font-weight: bolder;
	cursor: pointer;
	
	
}

.game_footer_text>span{
	font-family: 'Roboto Condensed', sans-serif;
}

.bottom-strip{
	background-color: '#222'; 
	padding: '10px 10px 5px 10px'; 
	margin-top: '-10px';
}

.gameplayer_footer_group{
	color: #ffffff;
	font-weight: bolder;
	font-size: 16px;
	cursor: pointer;
}

.gameplayer_footer_group:hover{
	color: #ff7d00;
}



label, input { display:block; }
    input.text { margin-bottom:12px; width:95%; padding: .4em; }
    fieldset { padding:0; border:0; margin-top:25px; }
    h1 { font-size: 1.2em; margin: .6em 0; }
    div#users-contain { width: 350px; margin: 20px 0; }
    div#users-contain table { margin: 1em 0; border-collapse: collapse; width: 100%; }
    div#users-contain table td, div#users-contain table th { border: 1px solid #eee; padding: .6em 10px; text-align: left; }
    .ui-dialog .ui-state-error { padding: .3em; }
    .validateTips { border: 1px solid transparent; padding: 0.3em; }



	.promo-container {
		background-color: #141414;
		padding: 50px 0px 50px 0px;
		/* border: 2px solid #666; */
		border-radius: 5px;
		text-align: center;
	  }
	  .promo-code {
		font-size: 20px;
		margin-bottom: 20px;
	  }
	  .copy-btn {
		cursor: pointer;
	  }
  
	  #promo{
		border: 2px dotted #fff;
		border-radius: 10px;
		padding: 5px;
		font-weight: bolder;
	  }
	  hr{
		border-bottom: 1px solid #333;
	  }



	  /* Ensures the video covers the entire viewport */
.video-container {
	position: relative;
	width: 100%;
	height: 100vh; /* Full viewport height */
	overflow: hidden;
	background-color: #000000ab;
  }
  
  .background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensures the video covers the container */
	z-index: -1; /* Keeps the video behind other content */
	
  }
  
  .video-content {
	position: relative;
	z-index: 1; /* Content will be on top of the video */
	color: white; /* Ensures text is readable over the video */
	text-align: center;
	margin-top: 10%;
  }

  @font-face {
	font-family: ATpinco;
	src: url('../fonts/at-pinko-bold.ttf');
  }